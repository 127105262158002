import React, { useState, useEffect } from 'react';
import axios from 'axios';

const MyForm = () => {
  const [formData, setFormData] = useState({
    fullName: '',
    phoneNumber: '',
    email: '',
    serviceType: '',
    comment: ''
  });
  const [successMessage, setSuccessMessage] = useState('');
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [recaptchaToken, setRecaptchaToken] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    const loadRecaptchaScript = () => {
      const script = document.createElement('script');
      script.src = 'https://www.google.com/recaptcha/api.js?render=6Ldk67gpAAAAAExAMZH--4E48U3RQpCTOdXtaAWO';
      script.async = true;
      document.body.appendChild(script);
    };
    loadRecaptchaScript();

    return () => {
      document.body.removeChild(document.body.lastChild);
    };
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === 'phoneNumber' && !/^\d*$/.test(value)) {
      return;
    }
    setFormData({ ...formData, [name]: value });

    if (errors[name]) {
      setErrors({ ...errors, [name]: '' });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const errors = validateForm(formData);
    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      setLoading(false);
      return;
    }

    try {
      if (recaptchaToken === '') {
        window.grecaptcha.ready(async () => {
          const token = await window.grecaptcha.execute('6Ldk67gpAAAAAExAMZH--4E48U3RQpCTOdXtaAWO', { action: 'submit' });
          setRecaptchaToken(token);

          const response = await axios.post('https://admin.bookmytow.com.au/sendInquiry', { ...formData, recaptchaToken: token });

          console.log('Response from API:', response.data);
          setSuccessMessage('Your request has been submitted. Our team shall reach out to you soon.');
          setFormData({
            fullName: '',
            phoneNumber: '',
            email: '',
            serviceType: '',
            comment: ''
          });
          setErrors({});
          setRecaptchaToken('');
          setLoading(false);
          setIsModalOpen(true);
          setTimeout(() => {
            setSuccessMessage('');
          }, 6000);
        });
      }
    } catch (error) {
      console.error('Error submitting form:', error);
      setLoading(false);
    }
  };

  const validateForm = (formData) => {
    let errors = {};

    if (!formData.fullName.trim()) {
      errors.fullName = 'Full Name is required';
    }

    if (!formData.phoneNumber.trim()) {
      errors.phoneNumber = 'Phone number is required';
    } else if (!/^\d{9}$/.test(formData.phoneNumber.trim())) {
      errors.phoneNumber = 'Phone number must be exactly 9 digits';
    }

    if (!formData.email.trim()) {
      errors.email = 'Email is required';
    } else if (!/\S+@\S+\.\S+/.test(formData.email.trim())) {
      errors.email = 'Invalid email format';
    }

    if (!formData.serviceType.trim()) {
      errors.serviceType = 'Service type is required';
    }
    return errors;
  };

  return (
    <section id="getaquote" className="contact-us-section ptb-100">
      <div className="container">
        <div className="row justify-content-around">
          <div className="col-md-12 col-lg-12 mb-12 mb-md-12 mb-sm-12 mb-lg-0">
            <div className="contact-us-form gray-light-bg rounded p-5">
              <div className="col-md-12 col-lg-12">
                <div className="section-heading text-center">
                  <h2 className="text-gold">Get a Quote</h2>
                  <p className="text-white mb1 lead">Fill up the form and experience towing like never before. Our dedicated Towing Expert will be reaching out to you within 10 minutes</p>
                </div>
              </div>
              <form method="POST" id="contactForm" className="contact-us-form" onSubmit={handleSubmit}>
                <div className="form-row">
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <div className="form-group">
                      <input type="text" className={`form-control contact_text ${errors.fullName && 'is-invalid'}`} name="fullName" value={formData.fullName} onChange={handleChange} placeholder="Full Name" />
                      {errors.fullName && <div className="invalid-feedback">{errors.fullName}</div>}
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <div className="form-group">
                      <input type="tel" className={`form-control contact_text ${errors.phoneNumber && 'is-invalid'}`} name="phoneNumber" value={formData.phoneNumber} onChange={handleChange} placeholder="Phone number" maxLength="9" />
                      {errors.phoneNumber && <div className="invalid-feedback">{errors.phoneNumber}</div>}
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <div className="form-group">
                      <input type="text" className={`form-control contact_text ${errors.email && 'is-invalid'}`} name="email" value={formData.email} onChange={handleChange} placeholder="Email" />
                      {errors.email && <div className="invalid-feedback">{errors.email}</div>}
                    </div>
                  </div>

                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <div className="form-group">
                      <select className={`form-control contact_text ${errors.serviceType && 'is-invalid'}`} name="serviceType" value={formData.serviceType} onChange={handleChange} placeholder="serviceType">
                        <option value="">Select Service Type</option>
                        <option value="1">Breakdown</option>
                        <option value="2">Truck & Machinery</option>
                        <option value="3">Out of Fuel</option>
                        <option value="4">Tyre Replacement</option>
                      </select>
                      {errors.serviceType && <div className="invalid-feedback">{errors.serviceType}</div>}
                    </div>
                  </div>

                  <div className="col-12">
                    <div className="form-group">
                      <textarea id="message" className={`form-control contact_text ${errors.comment && 'is-invalid'}`} name="comment" value={formData.comment} onChange={handleChange} placeholder="Comment"></textarea>
                      {errors.comment && <div className="invalid-feedback">{errors.comment}</div>}
                    </div>
                  </div>

                  <div className="col-sm-12 mt-3">
                    <button type="submit" className="btn btn-brand btn-rounded btn-contact-data" id="btnContactUs" disabled={loading}>
                      {loading ? 'Submitting...' : 'Get a Quote'}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {isModalOpen && (
        <div className="modal">
          <div className="modal-content">
            <center>
              <img src="assets/img/success.gif" alt="logo" className="success_image" />
              <h2 className="text-gold">Thank You!</h2>
              <p>Your request for quote has been submitted. Our team shall reach out to you soon.</p>
              <button type="button" onClick={() => setIsModalOpen(false)} className="btn btn-brand btn-rounded btn-contact-data">Close</button>
            </center>
          </div>
        </div>
      )}
    </section>
  );
};

export default MyForm;
