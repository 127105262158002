import React, { Component } from "react";

import Header from "../components/Header/header";
import Hero from "../components/HeroSection/HeroSection3";
import Promo from "../components/PromoSection/Promo3";
import AboutUs from "../components/AboutUs/AboutUs2";
import WorkProcess from "../components/WorkProcess";
import Counter from "../components/CallToAction/Counter";
import Faq from "../components/Faq";
import Testimonial from "../components/Testimonial";
import Contact from "../components/Contact";
import Footer from "../components/Footer";

class Theme extends Component {
  render() {
    return (
      <React.Fragment>
        <Header />
        <div className="main">
          <Hero />
          <Promo />
          <Counter />
          <AboutUs />
          <Contact />
          <WorkProcess />
          <Testimonial />
          <Faq />         
        </div>
        <Footer />
      </React.Fragment>
    );
  }
}
export default Theme;
