import React from "react";
import { connect } from "react-redux";
import { subscribe } from "../../actions/index";

class Footer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        email: "",
    };

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleFormValueChange(inputName, event) {
    let stateValue = {};
    stateValue[inputName] =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;
    this.setState(stateValue);
  }

  handleSubmit(event) {
    event.preventDefault();
    // get action
    const subscribeAction = subscribe(this.state);

    // Dispatch the contact from data
    this.props.dispatch(subscribeAction);

    // added delay to change button text to previous
    setTimeout(
      function() {
        // get action again to update state
        const subscribeAction = subscribe(this.state);

        // Dispatch the contact from data
        this.props.dispatch(subscribeAction);

        // clear form data
        this.setState({
          email: ""
        });
      }.bind(this),
      3000
    );
  }

  componentDidMount() {
    /**
     * Your ajax will goes here to get data then call setState
     */
  }

  render() {
    return (
      <React.Fragment>
        <footer className={"footer-1 gradient-bg pt-30 " + (this.props.withoutNewsletter && this.props.withoutNewsletter  === true ? "" : "" )}>
        {!(this.props.withoutNewsletter && this.props.withoutNewsletter  === true ) && (
        <div className="container">
           
        </div>
        )}

        <div className="container">
            <div className="row">
                <div className="col-md-12 col-lg-6 mb-6 mb-md-6 mb-sm-6 mb-lg-0">
                    <a href="/#" className="navbar-brand mb-2">
                        <img src="assets/img/logo-footer.png" alt="logo" className="img-fluid logo-footer" />
                    </a>
                    <a href="/#" className="navbar-brand mb-2">
                        <img src="assets/img/qr.png" alt="logo" className="img-fluid logo-footer width130" />
                    </a>
                    <br />
                    <p className="text-white lead">Our commitment to customer satisfaction is unparalleled, and we strive to exceed your expectations with every service call.</p>
                    <div className="list-inline social-list-default background-color social-hover-2 mt-2">
                        <li className="list-inline-item"><a className="facebook" href="https://www.facebook.com/profile.php?id=61557852334303&mibextid=ZbWKwL" target="_blank"><i className="fab fa-facebook"></i></a></li>
                        <li className="list-inline-item"><a className="instagram" href="https://www.instagram.com/book_my_tow?igsh=eWRiOWxlZXBmeXFi" target="_blank"><i className="fab fa-instagram"></i></a></li>
                        <li className="list-inline-item"><a className="youtube" href="https://www.youtube.com/channel/UC5cMaT8XpMyWu4qhAF1Nu8A" target="_blank"><i className="fab fa-youtube"></i></a></li>
                    
                    </div>
                </div>
                <div className="col-md-12 col-lg-6">
                    <ul className="contact-info-list">
                                <li className="d-flex pb-3">
                                    <div className="contact-icon mr-3">
                                        <span className="fas fa-location-arrow  text-gold color-primary rounded-circle p-3"></span>
                                    </div>
                                    <div className="contact-text">
                                        <h5 className="mb-1 text-gold">Location</h5>
                                        <p className="text-white lead">
                                        <a href="" className="text-white cursorNone">Sydney, NSW | Canberra, ACT</a>
                                        </p>
                                    </div>
                                </li>
                                <li className="d-flex pb-3">
                                    <div className="contact-icon mr-3">
                                        <span className="fas fa-envelope text-gold color-primary rounded-circle p-3"></span>
                                    </div>
                                    <div className="contact-text">
                                        <h5 className="mb-1 text-gold">Email</h5>
                                        <p className="text-white lead">
                                         <a href="mailto:support@bookmytow.com.au" className="text-white">support@bookmytow.com.au</a>
                                        </p>
                                    </div>
                                </li>
                                <li className="d-flex pb-3 opacity1">
                                    <div className="contact-icon mr-3">
                                        <span className="fas fa-phone text-gold color-primary rounded-circle p-3"></span>
                                    </div>
                                    <div className="contact-text">
                                        <h5 className="mb-1 text-gold">Contact Sark Transport Pty Limited</h5>
                                        <p className="text-white lead">
                                         <a href="tel:1300 069 869" className="text-white">1300 069 869</a>, <a href="tel:0435 606 666" className="text-white">0435 606 666</a>
                                        </p>
                                    </div>
                                </li>
                            </ul>
                </div>
            </div>
        </div>
    </footer>
    <div className="footer-bottom py-3 gray-light-bg">
        <div className="container">
            <div className="row">
                <div className="col-md-6 col-lg-7">
                    <div className="copyright-wrap small-text">
                        <p className="mb-0 text-white lead">&copy; Book My Tow, All rights reserved</p>
                    </div>
                </div>
                <div className="col-md-6 col-lg-5">
                    <div className="terms-policy-wrap text-lg-right text-md-right text-left">
                        <ul className="list-inline">
                            <li className="list-inline-item"><a className="small-text lead" target="_blank"  href="/termsofuse">Terms of Use</a></li>
                            <li className="list-inline-item"><a className="small-text lead" target="_blank"  href="/privacypolicy">Privacy Policy</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
      </React.Fragment>
    );
  }
}

export default connect(state => ({
  state
}))(Footer);
