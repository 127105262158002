import React from "react";
import { connect } from "react-redux";

class Counter extends React.Component {
  render() {
    return (
      <React.Fragment>
        <section className="counter-section">
            <div className="container">
                <div className="row">
                    <div className="col-sm-6 col-md-6 col-lg-3">
                        <div className="text-white p-2 count-data text-center my-3">
                            <h1 className="mb-1 active font-weight-bolder"><span className="count-number">4600</span>+</h1>
                            <h4 class="text-black">Breakdown Towed</h4>
                        </div>
                    </div>
                    <div className="col-sm-6 col-md-6 col-lg-3">
                        <div className="text-white p-2 count-data text-center my-3">
                            <h1 className="mb-1 active font-weight-bolder"><span className="count-number">1200</span>+</h1>
                            <h4 class="text-black">Tyres Replaced</h4>
                        </div>
                    </div>
                    <div className="col-sm-6 col-md-6 col-lg-3">
                        <div className="text-white p-2 count-data text-center my-3">
                            <h1 className="mb-1 active font-weight-bolder"><span className="count-number">2300</span>+</h1>
                            <h4 class="text-black">Truck & Machinery Towed</h4>
                        </div>
                    </div>
                    <div className="col-sm-6 col-md-6 col-lg-3">
                        <div className="text-white p-2 count-data text-center my-3">
                            <h1 className="mb-1 active font-weight-bolder"><span className="count-number">98000</span>+</h1>
                            <h4 class="text-black">Litres Fuel Served</h4>
                        </div>
                    </div>
                </div>
            </div>
        </section>
      </React.Fragment>
    );
  }
}

export default connect(state => ({}))(Counter);
