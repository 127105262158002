import React, { Component } from "react";

class Blog extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    /**
     * Your ajax will goes here to get data then call setState
     */
  }

  render() {
    return (
      <React.Fragment>
        <section className="our-blog-section ptb50">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-md-12 col-lg-12 privacy_policy">
                        <h4><b>Privacy Policy</b></h4>
                      <p>Book My Tow ("we," "us," or "our") operates the towing application (Book My Tow) in Australia. We are committed to protecting your privacy and maintaining the confidentiality and security of your personal information. This Privacy Policy explains how we collect, use, disclose, and protect your information in accordance with the Australian Privacy Principles (APPs) under the Privacy Act 1988 (Cth) and other relevant laws.</p>
                      <h5><b>Introduction</b></h5>
                      <p>At Book My Tow, we understand the importance of privacy and are dedicated to safeguarding your personal information. This Privacy Policy explains how we collect, use, disclose, and protect your information in accordance with the Australian Privacy Principle (APPs) under the Privacy ACT 1988 (cth) and other relevant laws.</p>

                      <h5><b>Definitions</b></h5>
                      <ul>
                          <li><strong>‘Account’</strong> means a unique account created for you to access our service or parts of our service.</li>
                          <li><strong>‘Affiliate’</strong> means a unique entity that is controlled by, or is under common control with a party, where ‘control’ means ownership of 50% or more of the shares, equity interest, or other securities entitled to vote for election of directors or other managing authority.</li>
                          <li><strong>‘Application’</strong> means the software program provided by the company downloaded by YOU on any electronic device named Book My Tow.</li>
                          <li><strong>‘Company’</strong> (referred to as either ‘the company’, ‘we, ‘us’ or ‘our’ in this agreement) refers to Book My Tow.</li>
                          <li><strong>‘Cookies’</strong> are small files that are placed on your computer, mobile device, or any other device by a website, containing the details of your browsing history on that website among its many uses.</li>
                          <li><strong>‘Country’</strong> refers to the place in which it operates.</li>
                          <li><strong>‘Device’</strong> means any device that can access the service such as a computer, a mobile phone or tablet.</li>
                          <li><strong>‘Personal Data’</strong> is any information that relates to an identified or identifiable individual.</li>
                          <li><strong>‘Service’</strong> refers to the application or the website or both.</li>
                          <li><strong>‘Service Provider’</strong> means any natural or legal person who processes the data on behalf of the company. It refers to third party companies or individuals employed by the company to facilitate the service, to provide the service on behalf of the company, to perform services related to the service, or to assist the company in analysing how the service is used.</li>
                          <li><strong>‘Third Party Social Media Service’</strong> refers to any website or any social network website through which a user can log in or create an account to use the service.</li>
                          <li><strong>‘Usage Data’</strong> refers to data collected automatically, either generated by the use of the service or from the service infrastructure itself (for example, the duration of the page visit).</li>
                          <li><strong>‘Website’</strong> refers to Book My Tow, accessible from (Book My Tow).</li>
                          <li><strong>‘You’</strong> means the individual accessing or using the service, or the company, or other legal entity on behalf of which such individual is accessing or using the service, as applicable.</li>
                      </ul>

                      <h5><b>Information We Collect</b></h5>
                      <p>Personal information: When you use our service, we may collect various types of personal information that you voluntarily provide to us, including but not limited to:</p>
                      <ul>
                          <li>Contact information: such as your name, email address, phone number and address</li>
                          <li>Vehicle information such as license plate, vehicle make, vehicle model and colour</li>
                          <li>Payment information if you make transactions through our service</li>
                      </ul>

                      <p>Usage information: In addition to personal information, we may collect non-personal information about how you interact with our service such as:</p>
                      <ul>
                          <li>Device information including device type, operating system, and unique device identifiers.</li>
                          <li>Log data such as IP address, browser type, pages visited and timestamps.</li>
                          <li>Location data with your consent if relevant to the service.</li>
                      </ul>

                      <p>Data Deletion Upon Request: If a customer requests the deletion of their data through any communication channel, we, the company, will promptly delete the requested information within 30 days of receiving the request.</p>

                      <p>Automatic Data Deletion: We do not store any data beyond what is necessary for our services. All data collected is automatically deleted within 2 months of its collection date to ensure the protection and privacy of our users.</p>

                      <h5><b>Use of Information</b></h5>
                      <p>We may use the information we collect for various purposes, including but not limited to:</p>
                      <ul>
                          <li>Providing and maintaining the service.</li>
                          <li>Personalizing your experience and improving our service.</li>
                          <li>Processing transactions and payments.</li>
                          <li>Communicating with you, including responding to inquiries and providing customer support.</li>
                          <li>Analyzing usage patterns and trends to enhance the service.</li>
                          <li>Complying with legal obligations and protecting our rights and interests.</li>
                      </ul>

                      <h5><b>Data Sharing and Disclosure</b></h5>
                      <p>We may share your personal information with third parties in the following circumstances:</p>
                      <ul>
                          <li>Service providers: we may engage third party companies or individuals to facilitate the service on our behalf, provide technical support, perform service-related services, or assist us in analyzing how our service is used. These third parties have access to your personal information only to perform these tasks on our behalf and are obligated not to disclose or use it for any other purpose.</li>
                          <li>Business partners: we may share your personal information with trusted business partners to offer you certain products, services, or promotions that may be of interest to you. However, we will obtain your consent before sharing your information for such purposes.</li>
                      </ul>

                      <h5><b>Data Security</b></h5>
                      <p>We take the security of your personal information seriously and implement appropriate technical and organizational measures to protect it from unauthorized access, disclosure, alteration, or destruction. However, no method of transmission over the internet or electronic storage is completely secure, and we cannot guarantee absolute security.</p>

                      <h5><b>Your Rights</b></h5>
                      <p>You have certain rights regarding your personal information, including the right to:</p>
                      <ul>
                          <li>Access, correct, or delete your information.</li>
                          <li>Object to or restrict the processing of your information.</li>
                          <li>Withdraw consent for processing where applicable.</li>
                      </ul>

                      <h5><b>Children’s Privacy</b></h5>
                      <p>Our service is not directed at individuals under the age of 18, and we do not knowingly collect personal information from children. If you believe that your child has provided us with personal information, please contact us, and we will take steps to remove that information from our systems.</p>

                      <h5><b>Changes to This Privacy Policy</b></h5>
                      <p>We reserve the right to update or modify this Privacy Policy at any time. We will notify you of any changes by posting the updated Privacy Policy on this page with the revised effective date. Your continued use of the service after the posting of any modifications indicates your acceptance of the revised Privacy Policy.</p>

                      <h5><b>Contact Us</b></h5>
                      <p>If you have any questions, concerns, or requests regarding this Privacy Policy or our privacy practices, please contact us at: <a href="mailto:info@bookmytow.com.au">info@bookmytow.com.au</a>.</p>

                      <p>By using our service, you consent to the collection, use, and disclosure of your information as described in this Privacy Policy.</p>
                                      </div>
                </div>
            </div>
        </section>
      </React.Fragment>
    );
  }
}

export default Blog;
