import React, { Component } from "react";

class Blog extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    /**
     * Your ajax will goes here to get data then call setState
     */
  }

  render() {
    return (
      <React.Fragment>
        <section className="our-blog-section ptb50">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-md-12 col-lg-12">
                        <h4><b>Terms of Use</b></h4>
                        <p>These Terms and Conditions govern your use of the towing application provided by Book My Tow in Australia </p>
                        <p>By accessing or using the service, you agree to be bound by these Terms. If you disagree with any part of the Terms, you may not use the Service</p>
                        <div className="section-heading mb-5">
                            <h5><b>1. Use of Service</b></h5>
                            <ul>
                              <li>1.1 You must be at least 18 years old to use the Service.</li>
                              <li>1.2 You are responsible for maintaining the confidentiality of your account and password, and you agree to access responsibility for all activities that occur under your account or password.</li>
                              <li>1.3 You agree to use the service only for lawful purposes and in a manner consistent with these terms and any applicable laws and regulations.</li>
                            </ul>
                            <h5><b>2. User Accounts</b></h5>
                            <ul>
                              <li>2.1 In order to use certain features of the Service, you may be required to create an account. You agree to provide accurate, current, and complete information during the registration process and to update such information to keep it accurate, current and complete</li>
                              <li>2.2 You are solely responsible for safeguarding your account credentials and for any activities or actions under your account, whether or not you have authorised such activities or actions</li>
                            </ul>
                            <h5><b>3. Service Availability</b></h5>
                            <ul>
                              <li>3.1 The service may be subject to interruptions, errors, or delays beyond our control. We do not guarantee that the service will be uninterrupted, error-free, or available at all times</li>
                              <li>3.2 We reserve the right to modify, suspend, or discontinue the Service, or any part thereof, with or without notice, at any time and for any reason</li>
                            </ul>
                            <h5><b>4. Intellectual Property</b></h5>
                            <ul>
                              <li>4.1 All content included in the Service, including but not limited to text, graphics, logos, images, audio clips, and software, is the property of Book My Tow or its licensors and is protected by copyright laws and other intellectual property rights</li>
                              <li>4.2 You may not modify, copy, distribute, transmit, dispatch, perform, reproduce, publish, licence, create derivative works from, transfer, or sell any information, software, products, or service obtained from the Service without the prior written consent of Book My Tow</li>
                            </ul>

                            <h5><b>5. Limitation of Liability</b></h5>
                            <ul>
                              <li>5.1 To the fullest extent permitted by law, Book My Tow shall not be liable for any direct, indirect, incidental, special, consequential, or exemplary damages, including but not limited to damages for loss of profits, goodwill, use, data, or other intangible losses arising out of or in connection with your use of the Service</li>
                              <li>5.2 In no event shall Book My Tow be liable for any damages resulting from the use of inability to use the Service, whether based on warranty, contract, tort (including negligence), or any other legal theory, even if Book My Tow has been informed of the possibility of such damages </li>
                            </ul>

                            <h5><b>6. Governing Law</b></h5>
                            <ul>
                              <li>These terms shall be governed by and construed in accordance with the laws of Australia. Any dispute arising out of or in connection with these Terms shall be subject to the exclusive jurisdiction of the courts of Australia</li>
                            </ul>

                            <h5><b>7. Changes to Terms</b></h5>
                            <ul>
                              <li>Book My Tow reservice the right to update or modify these Terms at any time without prior notice. Any changes will be effective immediately upon posting the revised Terms on the Service. Your continued use of the Service after posting of the revised Terms constitutes your acceptance of such changes</li>
                            </ul>

                            <h5><b>8. Contact Us</b></h5>
                            <ul>
                              <li>If you have any questions or concerns regarding any Terms & Conditions, please contact us at: <a href="mailto:info@bookmytow.com.au" className="txt_golden">info@bookmytow.com.au</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>
      </React.Fragment>
    );
  }
}

export default Blog;
