import React from "react";
import { connect } from "react-redux";

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeSection: "home" // Initialize activeSection state
    };
  }

  // Function to handle smooth scrolling
  scrollToSection = (id) => {
    const element = document.getElementById(id);
    if (element) {
      window.scrollTo({
        behavior: "smooth",
        top: element.offsetTop
      });
    }
  };

  // Function to update active section based on scroll position
  handleScroll = () => {
    const scrollPosition = window.scrollY;
    const sections = ["home", "about", "features","getaquote", "process", "testimonial", "faq"];

    // Find the active section based on scroll position
    for (let i = sections.length - 1; i >= 0; i--) {
      const currentSection = document.getElementById(sections[i]);
      if (currentSection.offsetTop <= scrollPosition + 100) {
        this.setState({ activeSection: sections[i] });
        break;
      }
    }
  };

  componentDidMount() {
    // Add scroll event listener when component mounts
    window.addEventListener("scroll", this.handleScroll);
  }

  componentWillUnmount() {
    // Remove scroll event listener when component unmounts
    window.removeEventListener("scroll", this.handleScroll);
  }

  render() {
    return (
      <React.Fragment>
        <header className="header hideHeader">
          <nav className="navbar navbar-expand-lg fixed-top bg-transparent affix headerNew">
            <div className="container">
              <a className="navbar-brand" href="/">
                <img
                  src={
                    this.props.isColorLogo && this.props.isColorLogo === true
                      ? "assets/img/logo-header.png"
                      : "assets/img/logo-header.png"
                  }
                  alt="logo"
                  className="img-fluid logo_image"
                />
              </a>
              <button
                className="navbar-toggler"
                type="button"
                data-toggle="collapse"
                data-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="ti-menu"></span>
              </button>

              <div className="collapse navbar-collapse h-auto" id="navbarSupportedContent">
                <ul className="navbar-nav ml-auto menu">
                  <li>
                    <a
                      href="/#home"
                      className={this.state.activeSection === "home" ? "page-scroll active" : "page-scroll"}
                      onClick={() => this.scrollToSection("home")}
                    >
                      Home
                    </a>
                  </li>
                  <li>
                    <a
                      href="/#about"
                      className={this.state.activeSection === "about" ? "page-scroll active" : "page-scroll"}
                      onClick={() => this.scrollToSection("about")}
                    >
                      About
                    </a>
                  </li>
                  <li>
                    <a
                      href="/#features"
                      className={this.state.activeSection === "features" ? "page-scroll active" : "page-scroll"}
                      onClick={() => this.scrollToSection("features")}
                    >
                      Services
                    </a>
                  </li>
                  
                  <li>
                    <a
                      href="/#process"
                      className={this.state.activeSection === "process" ? "page-scroll active" : "page-scroll"}
                      onClick={() => this.scrollToSection("process")}
                    >
                      Work Process
                    </a>
                  </li>
                  <li>
                    <a
                      href="/#testimonial"
                      className={this.state.activeSection === "testimonial" ? "page-scroll active" : "page-scroll"}
                      onClick={() => this.scrollToSection("testimonial")}
                    >
                      Testimonial
                    </a>
                  </li>
                  <li>
                    <a
                      href="/#faq"
                      className={this.state.activeSection === "faq" ? "page-scroll active" : "page-scroll"}
                      onClick={() => this.scrollToSection("faq")}
                    >
                      FAQs
                    </a>
                  </li>
                  <li>
                    <a
                      href="/#getaquote"
                      className={this.state.activeSection === "getaquote" ? "page-scroll active" : "page-scroll"}
                      
                    >
                      <button type="submit" className="btn btn-brand btn-rounded btn-contact-data fw500" onClick={() => this.scrollToSection("getaquote")}>Get a Quote</button>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </nav>
        </header>
      </React.Fragment>
    );
  }
}

export default connect(state => ({}))(Header);
