import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { connect } from "react-redux";
import Home from "../themes/home";
import Privacypolicy from "../themes/privacypolicy";
import Termsofuse from "../themes/termsofuse";
import NotFound from '../components/NotFound';

class Routes extends React.Component {
  render() {
    return (
      <Router>
        <Switch>
          {/* Define specific routes */}
          <Route exact path="/" component={Home} />
          <Route exact path="/privacypolicy" component={Privacypolicy} />
          <Route exact path="/termsofuse" component={Termsofuse} />
        </Switch>
      </Router>
    );
  }
}

export default connect(state => ({
  state
}))(Routes);
