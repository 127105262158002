import React, { Component } from "react";
import _data from "../../data";

class Testimonial extends Component {
  constructor(props) {
    super(props);
    this.state = {
      testimonial: {}
    };
  }

  componentDidMount() {
    /**
     * Your ajax will goes here to get data then call setState
     */
    this.setState({
      testimonial: _data.testimonial
    });
  }

  render() {
    return (
      <React.Fragment>
        <section className="position-relative gradient-bg ptb-100" id="testimonial">
          <div className="container">
              <div className="row align-items-center">
                  <div className="col-md-12 col-lg-12 mb-4 mb-sm-4 mb-md-0 mb-lg-0">
                      <div className="testimonial-heading text-white">
                          <h2 className="text-gold" align="center">What Our Client Say About Book My Tow</h2>
                          <br/>
                      </div>
                  </div>
                  <div className="col-md-2 col-lg-2"></div>
                  <div className="col-md-8 col-lg-8">
                      <div className="testimonial-content-wrap">
                          <div className="owl-carousel owl-theme client-testimonial-1 dot-indicator testimonial-shape">
                              <div className="item">
                                  <div className="testimonial-quote-wrap">
                                      <div className="media author-info mb-3">
                                          <div className="media-body">
                                              <h5 className="mb-0 text-gold">Natham</h5>
                                          </div>
                                          <i className="fas fa-quote-right"></i>
                                      </div>
                                      <div className="client-say">
                                          <p className="text-black lead testimonial_txt">Not only did I have a flat tire and my car broke down in the middle of nowhere late at night, but also there was no one to help. At that point, I contacted BOOKMYTOW Towing Service. In addition, they responded to the situation immediately and their driver was courteous and polite. Then he took my car away and towed it rapidly to the nearest garage. Consequently, for their fast and assured service I would completely advise anyone to approach BOOKMYTOW Towing as soon as possible!</p>
                                      </div>
                                  </div>
                              </div>
                              <div className="item">
                                  <div className="testimonial-quote-wrap">
                                      <div className="media author-info mb-3">
                                          <div className="media-body">
                                              <h5 className="mb-0 text-gold">Samantha</h5>
                                          </div>
                                          <i className="fas fa-quote-right"></i>
                                      </div>
                                      <div className="client-say">
                                          <p className="text-black lead testimonial_txt">My unfortunate experience was that my car broke down on a busy road during peak hours. I am thankful to Book My Tow Service! Not only did they show up on time, but the tow truck driver also treated my vehicle with caution and delivered it safely to the garage. They efficiently provided a polite service that made my stress lessen.</p>
                                      </div>
                                  </div>
                              </div>
                              <div className="item">
                                  <div className="testimonial-quote-wrap">
                                      <div className="media author-info mb-3">
                                          <div className="media-body">
                                              <h5 className="mb-0 text-gold">Dean</h5>
                                          </div>
                                          <i className="fas fa-quote-right"></i>
                                      </div>
                                      <div className="client-say">
                                          <p className="text-black lead testimonial_txt">Book My Tow Service exceeded my expectations with their top-notch service when I found myself stranded with a dead battery in the middle of nowhere. From the moment I called, their team was friendly, reassuring, and efficient. The tow truck arrived promptly, and the driver went above and beyond to ensure my safety and comfort throughout the entire process. I'm grateful for their professionalism and would highly recommend them to anyone in need of assistance on the road. They send photos post delivery of the vehicle at the destination makes them trustworthy towing partner. Hats off!!</p>
                                      </div>
                                  </div>
                              </div>
                              <div className="item">
                                  <div className="testimonial-quote-wrap">
                                      <div className="media author-info mb-3">
                                          <div className="media-body">
                                              <h5 className="mb-0 text-gold">Laurie</h5>
                                          </div>
                                          <i className="fas fa-quote-right"></i>
                                      </div>
                                      <div className="client-say">
                                          <p className="text-black lead testimonial_txt">133869 saved my day , KUDOS to Book My Tow Service, they came to my rescue when my car’s engine overheated. Their driver arrived within 30 minutes despite the oppressive heat and evaluated the problem in no time at all. He efficiently hauled my vehicle to the closest garage where it was promptly fixed. The promptness combined with their proficiency in handling such matters humbled me and I would definitely not think twice before giving them another call whenever need arises.</p>
                                      </div>
                                  </div>
                              </div>
                              <div className="item">
                                  <div className="testimonial-quote-wrap">
                                      <div className="media author-info mb-3">
                                          <div className="media-body">
                                              <h5 className="mb-0 text-gold">Singh</h5>
                                          </div>
                                          <i className="fas fa-quote-right"></i>
                                      </div>
                                      <div className="client-say">
                                          <p className="text-black lead testimonial_txt">I will forever be grateful to Book My Tow Service for helping me out of a difficult situation when my car unexpectedly broke down in the middle of nowhere. In the meantime, I waited for the tow truck to come and pick up my car and their dispatcher was very helpful as he instructed me on what to do. The driver came quickly and behaved very professionally all through the process. I felt relieved that my vehicle was being handled by competent people.</p>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
                  <div className="col-md-2 col-lg-2"></div>
              </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default Testimonial;
