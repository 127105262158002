import React from "react";
import { connect } from "react-redux";

class WorkProcess extends React.Component {
  

  render() {
    return (
      <React.Fragment>
        <section id="process" className={"work-process-section position-relative pb-200 " + (this.props.removeTop && this.props.removeTop === true ? 'pb-200' : 'ptb-50') + (this.props.isGray && this.props.isGray === true ? 'gray-light-bg' : '')}>
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-md-9 col-lg-8">
                        <div className="section-heading text-center mb-5">
                            <h2 className="text-gold">Our Work Process</h2>
                            <p className="lead">
                                Seemless towing booking experience through our mobile application available on Apple App Store and Google Play Store.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="row align-items-center justify-content-md-center justify-content-sm-center">
                    <div className="col-md-12 col-lg-6">
                        <div className="work-process-wrap">
                            <div className="process-single-item">
                                <div className="process-icon-item left-shape">
                                    <div className="d-flex align-items-center">
                                        <div className="process-icon mr-4">
                                            <i className="fas fa-download color-primary text-gold"></i>
                                        </div>
                                        <div className="process-content text-left">
                                            <h5 className="text-gold">Download the App</h5>
                                            <p className="lead">We need your mobile number confirmation so that we can connect with you.</p>
                                        </div>
                                    </div>
                                    <svg x="0px" y="0px" width="312px" height="130px"  className="svg_line">
                                        <path className="dashed1" fill="none" stroke="rgb(95, 93, 93)" strokeWidth="1" strokeDasharray="1300" strokeDashoffset="0" d="M3.121,2.028 C3.121,2.028 1.003,124.928 99.352,81.226 C99.352,81.226 272.319,21.200 310.000,127.338"></path>
                                        <path className="dashed2" fill="none" stroke="#ffffff" strokeWidth="2" strokeDasharray="6" strokeDashoffset="1300" d="M3.121,2.028 C3.121,2.028 1.003,124.928 99.352,81.226 C99.352,81.226 272.319,21.200 310.000,127.338 "></path>
                                    </svg>
                                </div>
                            </div>
                            <div className="process-single-item right_process">
                                <div className="process-icon-item right-shape">
                                    <div className="d-flex align-items-center">
                                        <div className="process-icon ml-4">
                                            <i className="fas fa-cogs color-primary text-gold"></i>
                                        </div>
                                        <div className="process-content text-right">
                                            <h5  className="text-gold">Select the Service</h5>
                                            <p className="lead">Breakdown, Truck & Machinery, Out of Fuel and Tyre Replacement.</p>
                                        </div>
                                    </div>
                                    <svg x="0px" y="0px" width="312px" height="130px" className="svg_line">
                                        <path className="dashed1" fill="none" stroke="rgb(95, 93, 93)" strokeWidth="1" strokeDasharray="1300" strokeDashoffset="0" d="M311.000,0.997 C311.000,0.997 313.123,123.592 214.535,79.996 C214.535,79.996 41.149,20.122 3.377,125.996"></path>
                                        <path className="dashed2" fill="none" stroke="#ffffff" strokeWidth="2" strokeDasharray="6" strokeDashoffset="1300" d="M311.000,0.997 C311.000,0.997 313.123,123.592 214.535,79.996 C214.535,79.996 41.149,20.122 3.377,125.996"></path>
                                    </svg>
                                </div>
                            </div>

                            <div className="process-single-item d-none left_process">
                                <div className="process-icon-item left-shape">
                                    <div className="d-flex align-items-center">
                                        <div className="process-icon mr-4">
                                            <i className="fas fa-cogs color-primary text-gold"></i>
                                        </div>
                                        <div className="process-content text-left">
                                            <h5  className="text-gold">Select the Service</h5>
                                            <p className="lead">Breakdown, Truck & Machinery, Out of Fuel and Tyre Replacement.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                           
                            <div className="process-single-item">
                                <div className="process-icon-item left-shape mb-0">
                                    <div className="d-flex align-items-center">
                                        <div className="process-icon mr-4">
                                            <i className="fas fa-check color-primary text-gold"></i>
                                        </div>
                                        <div className="process-content text-left">
                                            <h5  className="text-gold">Book My Tow</h5>
                                            <p className="lead">Confirm your order and experience the hassle free towing.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-6">

                        <div className="screenshot-wrap">
                            <div className="screenshot-frame"></div>
                            <div className="screen-carousel owl-carousel owl-theme dot-indicator">
                                <img src="assets/img/screenshot/1.png" className="img-fluid" alt="screenshots" />
                                <img src="assets/img/screenshot/2.png" className="img-fluid" alt="screenshots" />
                                <img src="assets/img/screenshot/3.png" className="img-fluid" alt="screenshots" />
                                <img src="assets/img/screenshot/4.png" className="img-fluid" alt="screenshots" />
                                <img src="assets/img/screenshot/5.png" className="img-fluid" alt="screenshots" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
      </React.Fragment>
    );
  }
}

export default connect(state => ({
  state
}))(WorkProcess);
