import React from "react";

class About extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    /**
     * Your ajax will goes here to get data then call setState
     */
  }

  render() {
    return (
      <React.Fragment>
        <div className="overflow-hidden">
            <section className="promo-section ptb-100 position-relative overflow-hidden background-shape-img position-relative" id="features">
        {this.props.hideShape && this.props.hideShape === true && (
            <div className="effect-2 opacity-1">
                <svg version="1.1" x="0px" y="0px" viewBox="0 0 463.6 616" style={{enableBackground: "new 0 0 463.6 616"}} className="injected-svg svg_img dark-color">
                    <path d="M148.4,608.3C25.7,572.5-3.5,442.2,0.3,375.8s24.8-117,124.8-166.5s125.7-77.4,165-129.6 c43.2-57.4,96.5-94.4,127.9-73c63,43,53.9,280,14,358s-68.9,75.5-98.9,118.7S271,644,148.4,608.3z"></path>
                </svg>
            </div>
        )}
            <div className="container">
                <div className="row">
                    <div className="col-lg-7 col-md-10">
                        <div className="section-heading">
                            <h2 className="text-gold">No matter what, we get you transported to your destination</h2>
                            <p  className="lead">Whether you're stranded on the side of the road, stuck in a parking lot, or need assistance with long-distance transportation, you can rely on BOOK MY TOW to provide fast, efficient, and affordable towing solutions.</p>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6 col-lg-3">
                        <img src="assets/img/breakdown.png" alt="logo" className="service_image" />
                        <br/>
                        <h5 className="text-gold m12" align="center">Breakdown</h5>
                    </div>
                    <div className="col-md-6 col-lg-3">
                        <img src="assets/img/truckandmachinery.png" alt="image" className="service_image"/>
                        <br/>
                        <h5 className="text-gold m12" align="center">Truck & Machinery</h5>
                    </div>
                    <div className="col-md-6 col-lg-3">
                        <img src="assets/img/outoffuel.png" alt="image" className="service_image"/>
                        <br/>
                        <h5 className="text-gold m12" align="center">Out of Fuel</h5>
                    </div>
                    <div className="col-md-6 col-lg-3">
                        <img src="assets/img/tyrereplacement.png" alt="image" className="service_image"/>
                        <br/>
                        <h5 className="text-gold m12" align="center">Tyre Replacement</h5>
                    </div>
                </div>
            </div>
        </section>
        </div>
      </React.Fragment>
    );
  }
}

export default About;
