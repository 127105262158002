import React, { Component } from "react";

import Header from "../components/Header/headernew";
import Termsofuse from "../components/termsofuse";
import Footer from "../components/Footer/footer";

class Theme extends Component {
  render() {
    return (
      <React.Fragment>
        <Header />
        <div className="main headerBody">
          <Termsofuse />
        </div>
        <Footer withoutNewsletter={true} />
      </React.Fragment>
    );
  }
}

export default Theme;
