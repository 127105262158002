import React from "react";
import { connect } from "react-redux";
import _data from "../../data";

class PromoSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      promo: {}
    };
  }

  componentDidMount() {
    /**
     * Your ajax will goes here to get data then call setState
     */

    this.setState({
      promo: _data.promo
    });
  }

  render() {
    return (
      <React.Fragment>
        <section id="about" className={"position-relative overflow-hidden feature-section pt-100 "}>
                <div className="container">
                    <div className="row align-items-center justify-content-between">
                        <div className="col-md-12 col-lg-12">
                            <div className="feature-contents section-heading">
                                <h2 className="text-gold">The Towing Experts</h2>
                                <p className="lead">At Book My Tow, we understand that life on the road can sometimes take unexpected turns.</p>
                                <p className="lead">With years of experience serving NSW and surrounding areas, our team of skilled professionals are committed to delivering prompt and courteous assistance whenever you need it most. Our fleet of state-of-the-art tow trucks and equipment ensures that we can handle vehicles of all shapes and sizes, from compact cars to heavy-duty trucks.</p>
                                <ul className="check-list-wrap list-two-col py-3 lead">
                                    <li><img src="assets/img/icon/fastest_service.png" className="img-fluid icon_li" alt="about" />&nbsp;Fastest Service</li>
                                    <li><img src="assets/img/icon/safety_first.png" className="img-fluid icon_li" alt="about" />&nbsp;Safety First</li>
                                    <li><img src="assets/img/icon/availability.png" className="img-fluid icon_li" alt="about" />&nbsp;24/7 Availability</li>
                                    <li><img src="assets/img/icon/licensed_insured.png" className="img-fluid icon_li" alt="about" />&nbsp;Licensed and Insured</li>
                                    <li><img src="assets/img/icon/affordable_rates.png" className="img-fluid icon_li" alt="about" />&nbsp;Affordable Rates</li>
                                    <li><img src="assets/img/icon/local_inter_state.png" className="img-fluid icon_li" alt="about" />&nbsp;Local and Inter-State Towing</li>
                                    <li><img src="assets/img/icon/versatilefleet.png" className="img-fluid icon_li" alt="about" />&nbsp;Versatile Fleet</li> 
                                    <li><img src="assets/img/icon/customer_satisfaction.png" className="img-fluid icon_li" alt="about" />&nbsp;Customer Satisfaction</li>
                                    <li><img src="assets/img/icon/plants.png" className="img-fluid icon_li" alt="about" />&nbsp;Plant and Equipment</li>
                                    <li><img src="assets/img/icon/additional_services.png" className="img-fluid icon_li" alt="about" />&nbsp;Additional Services</li>
                                    
                                </ul>

                                <div className="action-btns mt-4">
                                   
                                </div>
                            </div>
                        </div>
                        {/*<div className="col-md-6 col-lg-5">
                            <div className="mask-image">
                                <img src="assets/img/thumbnail.jpg" className="img-fluid" alt="about" />
                                <div className="item-icon video-promo-content">
                                    <a href="https://www.youtube.com/watch?v=dzFuWLDvMIo" className="popup-youtube video-play-icon text-center m-auto"><span className="ti-control-play"></span> </a>
                                </div>
                            </div>
                        </div>*/}
                    </div>
                </div>
            </section>
      </React.Fragment>
    );
  }
}

export default connect(state => ({
  state
}))(PromoSection);
