import React from "react";
import axios from "axios";

class Faq extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      faqs: []
    };
  }

  componentDidMount() {
  axios.get("https://admin.bookmytow.com.au/api/getFAQs")
    .then(response => {
      const { data } = response.data; // Extract the array of FAQ items from the response
      if (Array.isArray(data)) {
        this.setState({ faqs: data });
      } else {
        console.error("Invalid data format: Expected an array");
        // Set an empty array as the default value for faqs
        this.setState({ faqs: [] });
      }
    })
    .catch(error => {
      console.error("Error fetching FAQs:", error);
      // Set an empty array as the default value for faqs
      this.setState({ faqs: [] });
    });
}


  render() {
  const { faqs } = this.state;
  // Calculate the midpoint index to split the faqs array into two halves
  const midpoint = Math.ceil(faqs.length / 2);
  // Divide the faqs array into two halves
  const firstHalf = faqs.slice(0, midpoint);
  const secondHalf = faqs.slice(midpoint);

  return (
    <React.Fragment>
      <section id="faq" className={"ptb-100 " + (this.props.isGray && this.props.isGray === true ? 'gray-light-bg' : '')}>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-9 col-lg-8">
              <div className="section-heading text-center mb-5">
                <h2 className="text-gold">Frequently Asked Queries</h2>
                <p className="lead">Your queries matter to us. Still have questions? Contact us through email at <a href="mailto:support@bookmytow.com.au" className="golden-text">support@bookmytow.com.au</a> or call us at <a href="tel:1300 069 869" className="golden-text">1300 069 869</a>.</p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 col-lg-6">
              <div id="accordion" className="accordion faq-wrap">
                {firstHalf.map((faq, index) => (
                  <div className="card mb-3" key={index}>
                    <a className="card-header" data-toggle="collapse" href={"#collapse" + index} aria-expanded="false">
                      <h6 className="mb-0 width90 d-inline-block lead">{faq.question}</h6>
                    </a>
                    <div id={"collapse" + index} className="collapse" data-parent="#accordion">
                      <div className="card-body white-bg">
                        <p className="lead">{faq.answer}</p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>

            <div className="col-md-12 col-lg-6">
              <div id="accordion2" className="accordion faq-wrap">
                {secondHalf.map((faq, index) => (
                  <div className="card mb-3" key={index}>
                    <a className="card-header" data-toggle="collapse" href={"#collapse2" + index} aria-expanded="false">
                      <h6 className="mb-0 width90 d-inline-block lead">{faq.question}</h6>
                    </a>
                    <div id={"collapse2" + index} className="collapse" data-parent="#accordion2">
                      <div className="card-body white-bg">
                        <p className="lead">{faq.answer}</p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}

}

export default Faq;
